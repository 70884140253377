import { Model } from '@vuex-orm/core'

export default class Member extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'members'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      projId: this.string(),
      userId: this.string(),
      email: this.string(),
      role: this.string(),
      confirm: this.boolean(),
      created_at: this.string(),
      updated_at: this.string(),
    }
  }

  static users (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/members/users`,
      ...options,
      dataTransformer: (response) => {
        return response.data.data || []
      },
    }).then((res) => res.response.data)
  }

  static gets (projId = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/members/project/${projId}`,
      ...options,
      dataTransformer: (response) => {
        return response.data.data || []
      },
    }).then((res) => res.response.data)
  }

  static invite (projId = "", options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/members/invite/${projId}`,
      ...options
    }).then((res) => res.response)
  }

  static inviteUser (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/members/invite/user`,
      ...options
    }).then((res) => res.response)
  }

  static update (memberId = "", options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/cms/members/${memberId}`,
      ...options
    }).then((res) => res.response)
  }
}