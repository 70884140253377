import { Model } from '@vuex-orm/core'

export default class Alert extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'alert'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      show: this.boolean(),
      text: this.string(),
      type: this.string(),
    }
  }

  static set(data = {}) {
    return this.insert({
      data: {
        id: 1,
        ...data,
      }
    })
  }
}