import { Model } from '@vuex-orm/core'

export default class User extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'users'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      email: this.string(),
      name: this.string(),
      role: this.string(),
      created_at: this.string(),
      updated_at: this.string(),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/users`,
      ...options,
      dataTransformer: (response) => {
        return response.data.data || []
      },
    }).then((res) => res.response.data)
  }

  static emails (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/users`,
      ...options,
      dataTransformer: () => [],
    }).then((res) => {
      return res.response.data && res.response.data.data.map((e) => e.email)
    })
  }
}