<template>
  <v-app id="inspire">
    <!-- Alert -->
    <Alert/>
    <slot/>
  </v-app>
</template>

<script>
import Alert from "@/components/Alert";

export default {
  components: {
    Alert,
  },
}
</script>