<template>
  <div class="mt-5">
    <v-select
      v-model="projId"
      :items="projects"
      item-value="id"
      item-text="name"
      placeholder="Select App"
      no-data-text
      dense
      @change="_select"
    >
      <template v-if="$rolesUser[$profile.role] === $rolesUser['super-admin']" v-slot:prepend-item>
        <v-list-item ripple @click="_add">
          <v-list-item-content>
            <v-list-item-title>New App</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
    <!-- Dialog -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create App</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" :lazy-validation="false">
            <v-text-field
              v-model="form.name"
              :rules="[rules.required]"
              label="Name"
            ></v-text-field>

            <v-text-field
              v-model="form.callback"
              :rules="[rules.url]"
              label="Callback URL"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="_save()" :disabled="!valid"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";

import ProjId from "@/store/models/ProjId";
import Project from "@/store/models/Project";

export default {
  data: () => {
    return {
      project: "",
      form: {},
      rules: {
        required: (value) => !!value || "Required.",
        url: (value) => {
          if (value) {
            const pattern = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
            return pattern.test(value) || "Invalid URL.";
          } else {
            return true;
          }
        },
      },
      valid: false,
      dialog: false,
    };
  },
  computed: {
    projId: {
      get() {
        const project = ProjId.find(1);
        return (project && project.value) || {};
      },
      set(val) {
        this.project = val;
      },
    },
    projects() {
      return Project.all() || [];
    },
  },
  created() {
    this.gets();
  },
  methods: {
    async gets() {
      // const options = {
      //   params: {
      //     page: 1,
      //     perpage: 0,
      //   },
      // };

      const projs = await Project.gets().catch((err) =>
        console.error("Get projects err: ", err)
      );
      const projects = (projs && projs.data) || [];

      // Create dialog
      if (projects.length === 0) {
        this.dialog = true;
      }
      const projId = this.$ls.get("projId");

      // Auto Select
      let project = projects.find((e) => e.id === projId);
      if (!(project && project.id)) {
        project = projects[0];
      }

      this.$ls.set("projId", project.id);
      this.setProjId({
        value: project.id,
        access: project.access,
      });
    },
    _select(e) {
      this.$ls.set("projId", e);
      const project = Project.find(e);

      this.setProjId({
        value: e,
        access: project && project.access,
      });
    },
    _add() {
      this.dialog = true;
    },
    _save() {
      if (!this.valid) {
        return;
      }

      this.dialog = true;

      const options = {
        data: this.form,
      };

      Project.create(options)
        .then((res) => {
          this.dialog = false;
          this.form = {};

          if (res && res.data && res.data.id) {
            this.setProjId({
              value: res.data.id,
            });
          }
        })
        .catch((err) => {
          this.dialog = false;
          console.error("_save err: ", err);
        });
    },
    setProjId(data = {}) {
      this.$store
        .$db()
        .model("projId")
        .insert({
          data: { id: 1, ...data },
        });
      Vue.prototype.$project = { id: 1, ...data };
    },
  },
};
</script>