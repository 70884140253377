<template>
  <!-- Alert -->
  <div class="s-alert" v-if="alert">
    <v-alert
      v-model="alert"
      dismissible
      close-icon="mdi-close-circle"
      border="left"
      elevation="2"
      colored-border
      :type="data.type"
    >
      <p v-html="data.text"/>
    </v-alert>
  </div>
</template>

<script>
import Alert from "@/store/models/Alert";

export default {
  data: () => {
    return {};
  },
  computed: {
    data() {
      return Alert.find(1) || {};
    },
    alert: {
      get() {
        const alert = Alert.find(1) || {};
        /* eslint-disable */
        if (alert.show) {
          setTimeout(() => {
            this.set(!alert.show);
          }, 3000);
        }

        return alert.show || false;
      },
      set(val) {
        this.set(val);
      },
    },
  },
  methods: {
    set(val) {
      Alert.set({
        show: val,
      });
    },
  },
};
</script>
<style scoped>
  .s-alert {
    position: fixed;
    z-index:10;
    left: auto;
    top: 10px;
    right: 10px;
    width: 400px;
  }
</style>