import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import axios from 'axios'
import router from '@/router'

import Alert from '@/store/models/Alert'
import Loader from '@/store/models/Loader'
import Auth from '@/store/models/Auth'
import User from '@/store/models/User'
import ProjId from '@/store/models/ProjId'
import Project from '@/store/models/Project'
import Member from '@/store/models/Member'
import Form from '@/store/models/Form'
import Survey from '@/store/models/Survey'
import File from '@/store/models/File'

const token = localStorage.getItem("token")
let headers = {}
if (token) {
  headers = {
    Authorization: `Bearer ${token}`
  }
}

const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers
})

base.interceptors.request.use(
  (request) => {
    Loader.set(true);
    return request;
  }
)

base.interceptors.response.use((response) => {
  if (response.data && response.data.accessToken) {
    localStorage.setItem("token", response.data.accessToken)
    base.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`
  }

  Loader.set(false)
  return response
}, (error) => {
  Loader.set(false)
  let message = error.response.data && error.response.data.message || "Some thing went wrong!"
  if(error.response.request.responseType === 'arraybuffer' && error.response.data && error.response.data.toString() === '[object ArrayBuffer]') {
    try {
      const err = JSON.parse(Buffer.from(error.response.data).toString('utf8'))
      message = (err && err.message) || message
    } catch (error) {
      console.error(error)
    }
  }
  if (error.response.status === 401 || message.trim() === "missing or malformed jwt") {
    base.defaults.headers.Authorization = ""
    localStorage.setItem("token", "")
    router.push({ path: "/login" })
  } else {
    setTimeout(() => {
      Alert.set({
        show: true,
        text: message,
        type: "error",
      })
    }, 300);
  }

  throw error;
});

Vue.use(Vuex)
VuexORM.use(VuexORMAxios, {
  axios: base
})

// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(Alert)
database.register(Loader)
database.register(Auth)
database.register(User)
database.register(ProjId)
database.register(Project)
database.register(Member)
database.register(Form)
database.register(Survey)
database.register(File)

// Create Vuex Store and register database through Vuex ORM.
export default new Vuex.Store({
  plugins: [VuexORM.install(database)]
})
