import { Model } from '@vuex-orm/core'

export default class Auth extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'auth'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      email: this.string(),
      name: this.string(),
      role: this.string(),
    }
  }

  static inviteActive (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/users/invite/active`,
      ...options,
      dataTransformer: () => [],
    }).then((res) => res.response.data)
  }

  static register (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/users/register`,
      ...options,
      dataTransformer: (response) => {
        return {
          ...response.data,
          id: 1,
        }
      },
    }).then((res) => res.response.data)
  }

  static login (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/users/login`,
      ...options,
      dataTransformer: (response) => {
        return {
          ...response.data,
          id: 1,
        }
      },
    }).then((res) => res.response.data)
  }

  static forgot (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/users/forgot/password`,
      ...options,
      dataTransformer: () => [],
    }).then((res) => res.response.data)
  }

  static resetPwd (options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/cms/users/reset/password`,
      ...options,
      dataTransformer: () => [],
    }).then((res) => res.response.data)
  }

  static profile (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/users/profile`,
      ...options,
      dataTransformer: (response) => {
        return {
          ...response.data,
          id: 1,
        }
      },
    }).then((res) => res.response.data)
  }

  static logout (options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/cms/users/logout`,
      dataTransformer: () => {
        this.delete(1)
        return []
      },
      ...options,
    }).then((res) => res.response.data)
  }
}