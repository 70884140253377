import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'
import vueTopprogress from 'vue-top-progress'
import DatetimePicker from 'vuetify-datetime-picker'

import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'

import DefaultLayout from '@/layouts/Default'
import EmptyLayout from '@/layouts/Empty'

Vue.config.productionTip = false

Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true,
})

Vue.use(vueTopprogress)
Vue.use(DatetimePicker)

Vue.component('defaultLayout', DefaultLayout)
Vue.component('emptyLayout', EmptyLayout)

Vue.prototype.$barName = 'Home'

Vue.prototype.$regexEmail = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.(?:[a-zA-Z0-9-]+)*$/,
  ''
)
Vue.prototype.$regexPwd = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)+[a-zA-Z0-9]*$/,
  ''
)
Vue.prototype.$regexPwdTxt =
  'Min. 6 characters with at least one capital letter, a number.'

Vue.prototype.$rolesUser = {
  'super-admin': 1,
  'app-admin': 2,
}

Vue.prototype.$rolesMember = [
  { text: 'Administrator', value: 'administrator' },
  { text: 'Viewer', value: 'viewer' },
]
Vue.prototype.$profile = {}
Vue.prototype.$project = {}

Vue.prototype.$itemsPerPage = [10, 20, 30, 40, 50, 100]

/* eslint-disable no-new */
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
