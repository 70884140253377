import { Model } from '@vuex-orm/core'

export default class Survey extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'surveys'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      projId: this.string(),
      project: this.attr(null),
      formId: this.string(),
      form: this.attr(null),
      json: this.attr(null),
      answer: this.attr(null),
      files: this.attr(null),
      approve: this.attr(null),
      location: this.attr(null),
      callback: this.string(),
      approveURL: this.string(),
      created_at: this.string(),
      updated_at: this.string(),
    }
  }

  static gets(formId = '', options = {}) {
    // Condition
    if (!options.params.condition) {
      options.params.condition = {}
    }
    options.params.condition = JSON.stringify({
      ...options.params.condition,
      formId,
    })

    return this.api()
      .request({
        method: 'GET',
        url: `/cms/surveys`,
        ...options,
        dataTransformer: (response) => {
          return (response && response.data && response.data.data) || []
        },
      })
      .then((res) => res && res.response && res.response.data)
  }

  static get(surveyId = '', options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/cms/surveys/${surveyId}`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static answer(formId = '', options = {}) {
    // Condition
    if (!options.params.condition) {
      options.params.condition = {}
    }
    options.params.condition = JSON.stringify({
      ...options.params.condition,
      formId,
    })

    return this.api()
      .request({
        method: 'GET',
        url: `/cms/surveys/answer`,
        ...options,
        dataTransformer: (response) => {
          return (response && response.data && response.data.data) || []
        },
      })
      .then((res) => res && res.response && res.response.data)
  }

  static report(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/cms/surveys/timeseries`,
        ...options,
        dataTransformer: () => [],
      })
      .then((res) => res && res.response && res.response.data)
  }

  static create(options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/api/surveys`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static update(surveyId = '', options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/api/surveys/${surveyId}`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static delete(id = '', options = {}) {
    return this.api()
      .request({
        method: 'DELETE',
        url: `/cms/surveys/${id}`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static approve(surveyId = '', options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/cms/surveys/approve/${surveyId}`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static approveBulk(options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/cms/surveys/approve/bulk`,
        ...options,
        dataTransformer: () => [],
      })
      .then((res) => res.response.data)
  }

  static export(formId = '', options = {}) {
    options.params.options = JSON.stringify({ ...options.params.options })
    return this.api()
      .request({
        method: 'GET',
        url: `/cms/surveys/download/${formId}`,
        ...options,
        dataTransformer: () => [],
      })
      .then((res) => res.response.data)
  }

  static json(formId = '', options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/cms/surveys/download/json/${formId}`,
        ...options,
        dataTransformer: () => [],
      })
      .then((res) => res.response.data)
  }

  static editableToken(surveyId = '', options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/cms/surveys/token/${surveyId}`,
        ...options,
        dataTransformer: () => [],
      })
      .then((res) => res.response.data)
  }
}
