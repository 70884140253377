import { Model } from '@vuex-orm/core'

export default class Project extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'projects'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      name: this.string(),
      callback: this.string(),
      secretKey: this.string(),
      access: this.attr({}),
      created_at: this.string(),
      updated_at: this.string(),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/projects`,
      ...options,
      dataTransformer: (response) => {
        return response.data.data || []
      },
    }).then((res) => res.response.data)
  }

  static create (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/projects`,
      ...options
    }).then((res) => res.response)
  }

  static update (projId = "", options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/cms/projects/${projId}`,
      ...options
    }).then((res) => res.response)
  }
}