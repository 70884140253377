import { Model } from '@vuex-orm/core'

export default class Loader extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'loader'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      show: this.boolean(),
    }
  }

  static set(show) {
    return this.insert({
      data: {
        id: 1,
        show,
      }
    })
  }
}