<template>
  <v-app id="inspire">
    <!-- Alert -->
    <Alert/>
    <!-- Drawer -->
    <slot name="drawer">
      <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <img src="../assets/avatar.png">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{profile && (profile.name || profile.email)}}</v-list-item-title>
              <v-list-item-subtitle>{{profile && profile.role}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          
          <template v-for="item in items">
            <v-row v-if="item.heading" :key="item.heading" align="center">
              <v-col cols="6">
                <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
              </v-col>
              <v-col cols="6" class="text-center">
                <a href="#!" class="body-2 black--text">EDIT</a>
              </v-col>
            </v-row>
            <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, i) in item.children" :key="i" link>
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="item.text" link @click="navigate(item.path)">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn color="primary" block @click="logout">Logout</v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </slot>

    <!-- Navbar -->
    <slot name="navbar">
      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="ml-0 pl-4 mr-8">
          <span class="hidden-sm-and-down">{{$barName}}</span>
        </v-toolbar-title>
        <SelectProject style="width: 250px"></SelectProject>
      </v-app-bar>
    </slot>

    <!-- Main -->
    <v-main>
      <slot/>
    </v-main>
  </v-app>
</template>

<script>
import Auth from "@/store/models/Auth";
import Project from "@/store/models/Project";

import Alert from "@/components/Alert";
import SelectProject from "@/components/SelectProject";

export default {
  components: {
    Alert,
    SelectProject,
  },
  props: {},
  computed: {
    profile() {
      const auth = this.$store.$db().model("auth").find(1);
      const role = (auth && auth.role)
      /* eslint-disable */
      if(this.$rolesUser[role] <= this.$rolesUser["super-admin"] && !this.adminmn) {
        this.adminmn = true;
        this.items = [
          { icon: "mdi-head-minus", text: "Admin Management", path: "/user" },
          ...this.items,
        ]
      }

      return auth;
    },
  },
  data: () => ({
    adminmn: false,
    dialog: false,
    drawer: null,
    items: [
      { icon: "mdi-account", text: "Member Management", path: "/member" },
      { icon: "mdi-content-paste", text: "Survey Management", path: "/form" },
      { icon: "mdi-cogs", text: "Setting", path: "/setting" },
    ],
  }),
  methods: {
    navigate(page) {
      this.$router.push({ path: page });
    },
    logout() {
      Project.deleteAll();
      Auth.logout()
        .then((res) => {
          localStorage.setItem("token", "");
          this.navigate("/login");
          return res;
        })
        .catch((err) => console.error("Logout error: ", err));
    },
  },
};
</script>