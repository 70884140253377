import Vue from 'vue'
import Router from 'vue-router'

import Auth from '@/store/models/Auth'

Vue.use(Router)

const Home = () => import('@/views/Home.vue')
const Register = () => import('@/views/Register.vue')
const Login = () => import('@/views/Login.vue')
const Forgot = () => import('@/views/Forgot.vue')
const Reset = () => import('@/views/Reset.vue')
const User = () => import('@/views/User.vue')
const Member = () => import('@/views/Member.vue')
const Setting = () => import('@/views/Setting.vue')
const Form = () => import('@/views/Form.vue')
const Survey = () => import('@/views/Survey.vue')
const Creator = () => import('@/views/Creator.vue')
const Approve = () => import('@/views/Approve.vue')
const Analytics = () =>
  import(/* webpackChunkName: "creator" */ '@/views/Analytics.vue')

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Home,
    },
    { path: '/survey/invite', name: 'Register', component: Register },
    { path: '/login', name: 'Login', component: Login },
    { path: '/forgot/password', name: 'Forgot', component: Forgot },
    { path: '/forgot/password/:key', name: 'Reset', component: Reset },
    {
      path: '/user',
      name: 'Admin Management',
      meta: {
        layout: 'defaultLayout',
        requiresAuth: true,
        role: 'super-admin',
      },
      component: User,
    },
    {
      path: '/member',
      name: 'Member Management',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Member,
    },
    {
      path: '/setting',
      name: 'Setting',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Setting,
    },
    {
      path: '/form',
      name: 'Survey Management',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Form,
    },
    { path: '/survey/:id', name: 'Survey', component: Survey },
    { path: '/survey/app/:appId', name: 'Survey App', component: Survey },
    {
      path: '/creator',
      name: 'Creator',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Creator,
    },
    {
      path: '/approve/:id',
      name: 'Approve',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Approve,
    },
    {
      path: '/analytics/:id',
      name: 'Analytics',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: Analytics,
    },
  ],
})

router.beforeEach((to, from, next) => {
  Vue.prototype.$barName = to.name || ''

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token')
    if (!token) {
      next({ path: '/login' })
    }
  }

  next()
})

router.beforeResolve((to, from, next) => {
  const route = to.matched.find((record) => record.meta.role)
  const role = route && route.meta && route.meta.role

  if (role) {
    const roles = Vue.prototype.$rolesUser
    const auth = Auth.find(1)
    const arole = auth && auth.role

    if (roles[arole] > roles[role]) {
      next({ path: '/' })
    }
  }

  next()
})

export default router
