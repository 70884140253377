<template>
  <div id="app">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <component :is="layout">
      <router-view class="view"></router-view>
    </component>
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";

import Loader from "@/store/models/Loader";
import Auth from "@/store/models/Auth";

export default {
  name: "app",
  router: router,
  watch: {
    loader: {
      handler(cur) {
        if(cur.show) {
          this.$refs.topProgress.start();
        } else {
          this.$refs.topProgress.done();
        }
      },
      deep: false,
    },
    '$route' (to) {
      if(!this.first) {
        if(to && to.meta && to.meta.requiresAuth) {
          this._profile();
        }

        this.first = true;
      }
    },
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "emptyLayout";
    },
    loader() {
      const loader = Loader.find(1);
      return loader || {};
    },
  },
  data: () => {
    return {
      first: false,
    }
  },
  methods: {
    async _profile() {
      const token = this.$ls.get("token");
      if (token) {
        const profile = await Auth.profile().catch((err) =>
          console.error("Get profile error: ", err)
        );
        Vue.prototype.$profile = profile;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");

body {
  font-family: "Kanit", sans-serif !important;
}

.v-application {
  font-family: "Kanit", sans-serif !important;
}

#app {
  font-family: "Kanit", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.sv_q_file_input {
  color: inherit !important;
}

.pointer {
  cursor: pointer;
}

.v-data-table.approve-tb>.v-data-table__wrapper>table {
  min-width: max-content !important;
}

table.sub-tb {
  margin: 10px 0;
  min-width: max-content;
}

table.sub-tb>thead>tr>th {
  padding: 0 16px;
  text-align: center;
  background: #EEEEEE;
  border-spacing: 1px;
  border: 1px solid;
}

table.sub-tb>tbody>tr>td {
  padding: 0 16px;
  border-spacing: 1px;
  border: 1px solid;
}
</style>
