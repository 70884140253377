import { Model } from '@vuex-orm/core'

export default class File extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'files'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      name: this.string(),
      ext: this.string(),
      type: this.string(),
      uri: this.string(),
    }
  }

  static upload (formId = "", options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/api/files/upload/survey/${formId}`,
      ...options
    }).then((res) => res.response || res.response.data || {})
  }

  static download (file = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/files/download/${file}`,
      ...options,
      dataTransformer: () => [],
    }).then((res) => res.response || res.response.data || {})
  }
}