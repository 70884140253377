import { Model } from '@vuex-orm/core'

export default class Form extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'forms'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      projId: this.string(),
      name: this.string(),
      start: this.string(),
      stop: this.string(),
      callback: this.string(),
      active: this.boolean(),
      default: this.boolean(),
      editable: this.boolean(),
      approve: this.boolean(),
      approveURL: this.string(),
      css: this.string(),
      created_at: this.string(),
      updated_at: this.string(),
    }
  }

  static gets (projId = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/forms/project/${projId}`,
      ...options,
      dataTransformer: (response) => {
        return response.data.data || []
      },
    }).then((res) => res.response.data)
  }

  static item (formId = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/cms/forms/${formId}`,
      ...options,
    }).then((res) => res.response.data)
  }

  static get (formId = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/api/forms/survey/${formId}`,
      ...options,
    }).then((res) => res.response.data)
  }

  static latest (projId = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/api/forms/survey/latest/${projId}`,
      ...options,
    }).then((res) => res.response.data)
  }

  static create (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/forms`,
      ...options,
    }).then((res) => res.response.data)
  }

  static update (formId = "", options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/cms/forms/${formId}`,
      ...options,
    }).then((res) => res.response.data)
  } 

  static clone (formId = "", options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/cms/forms/clone/${formId}`,
      ...options,
    }).then((res) => res.response.data)
  }
}